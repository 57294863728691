<template>
  <div class="container">
    <content-title :nav="nav"></content-title>
    <el-form :inline="true">
      <el-form-item label="代理商">
        <el-input v-model="query.name" placeholder="输入代理商名称"></el-input>
      </el-form-item>
      <el-form-item label="类型">
        <el-select v-model="query.type" placeholder="选择代理商类型" clearable>
          <el-option label="代理商" value="agent"></el-option>
          <el-option label="二级代理商" value="sub_agent"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态">
        <el-select v-model="query.status" placeholder="选择" clearable>
          <el-option label="正常" :value="0"></el-option>
          <el-option label="冻结" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList(1)">搜索</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" v-permission="'addAgent'" @click="add">新增</el-button>
      </el-form-item>
    </el-form>
    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #level="{ row }">
        <div>{{ agentLevelMap[row.agent_level] }}</div>
      </template>
 <template #areas="{ row }">
        <div>{{ row.areas.join(',') }}</div>
      </template>

      
      <template #handle="{ row }">
        <el-button size="small" type="success" @click="update(row)"
          >编辑</el-button
        >
        <el-button size="small" type="primary" @click="details(row)"
          >详情</el-button
        >
       
        <el-button
          v-if="row.status === 0"
          size="small"
          type="danger"
          @click="updateStatus(row, 2)"
          >冻结</el-button
        >
        <el-button
          v-if="row.status === 2"
          size="small"
          type="success"
          @click="updateStatus(row, 0)"
          >解冻</el-button
        >
 
      </template>
    </auto-table>
    <AddorEdit @success="getList" ref="AddorEdit"></AddorEdit>

  </div>
</template>

<script>
import AddorEdit from "./components/AddorEdit.vue";


export default {
  components: {
    AddorEdit,

  },
  data() {
    return {
      nav: { firstNav: "代理商管理", secondNav: "代理商列表" },
      DataList: [],
      query: {
        name: "",
        type: "",
      },
       agentLevelMap: {
        province: "省代理",
        city: "市代理",
        district: "县区代理",
      },
      type: {
        agent: "代理商",
        sub_agent: "二级代理商",
      },
      Option: [
        { name: "代理商", value: "title" },
        { name: "联系人", value: "name" },
        { name: "联系电话", value: "phone" },
        { name: "类型", value: "level", type: "custom" },
        { name: "代理区域", value: "areas", type: "custom" },
        { name: "创建时间", value: "create_time" },
        { name: "操作", value: "handle", type: "custom", width: "300px" },
      ],
      Total: 0,
    };
  },

  mounted() {
    this.getList(1);
  },

  methods: {
    details(row) {
      this.$router.push({ name: "agentDetails", query: { id: row.id } });
    },
    adminDivisionHandle(row) {
      this.$refs.admindivi.visiable(row.id);
    },
    divisionHandle(row) {
      this.$refs.division.open(row.id);
    },
    add() {
      this.$refs.AddorEdit.open();
    },
    update(row) {
      this.$refs.AddorEdit.open(row);
    },
    userDetail(row) {
      this.$router.push({ name: "userDetails", params: { id: row.user_id } });
    },
    updateStatus(row, status) {
      this.$confirm("确定执行冻结/解冻操作？").then(() => {
        this.$axios({
          url: "/user/cooperator/agentFreeze",
          data: {
            id: row.id,
            status,
          },
          method: "post",
        }).then((res) => {
          this.getList(1);
        });
      });
    },
    //分成记录
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/cooperator/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
          title: this.query.name || null,
          types: this.query.type || "agent,sub_agent,agent_company,dev_advisor_agent",
          status: this.query.status,
        },
      }).then((res) => {
        res.data.data.rows.forEach(item=>{
          if(item.map.areas){
             item.areas =  item.map.areas.map(area=>{
                return area[area.type]
              })
          }else{
            item.areas = []
          }
        })
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>