export let addRule = {
    title: [
        { required: true, message: "请填写名称", trigger: "change" },
    ],
    name: [
        { required: true, message: "请填写联系人", trigger: "change" },
    ],
    phone: [
        { required: true, message: "请填写联系人电话", trigger: "change" },
    ],
    type: [
        { required: true, message: "请选择类型", trigger: "change" },
    ],
    agent_level: [
        { required: true, message: "请选择代理商级别", trigger: "change" },
    ],
    login_name: [
        { required: true, message: "请填写登录账号", trigger: "change" },
    ],

}

